import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUserData } from "../../selectors/userSelector";
import {
  selectHistory,
  selectSearchToggle,
} from "../../selectors/mainSelector";
import { Link } from "react-router-dom";
import { toggleSearch } from "../../actions/mainActions";
import { logout } from "../../actions/authActions";
import Search from "../Search";
import messages from "../languages";
import userMessages from "../../containers/Users/languages";
import { FormattedMessage, injectIntl } from "react-intl";
import ChangePasswordModal from "../../containers/Users/components/changePasswordModal";
import { updatePassword } from "../../actions/userActions";

const Sidebar = ({ intl }) => {
  const dispatch = useDispatch();
  const user = useSelector(getUserData);

  const location = useSelector(selectHistory);
  const openSearch = useSelector(selectSearchToggle);

  const [openedMenu, setopenedMenu] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);

  const _toggleSearch = () => {
    dispatch(toggleSearch());
  };

  const _logout = () => {
    dispatch(logout(intl));
  };

  const toggleMenu = () => {
    setopenedMenu(!openedMenu);
  };

  const openChangePasswordModal = () => {
    setIsChangePasswordModalOpen(!isChangePasswordModalOpen);
  };

  const _updatePassword = (data) => {
    dispatch(updatePassword(data, intl));
  };

  useEffect(() => {
    const hasShownChangePasswordModal = localStorage.getItem(
      "hasShownChangePasswordModal"
    );

    if (isFirstLoad) {
      if (
        hasShownChangePasswordModal === "false" &&
        user?.isFlaggedForPasswordRecreate
      ) {
        setIsChangePasswordModalOpen(true);
        localStorage.setItem("hasShownChangePasswordModal", true);
      } else {
        setIsChangePasswordModalOpen(false);
      }
    }

    setIsFirstLoad(false);
  }, [isFirstLoad, user?.isFlaggedForPasswordRecreate]);

  return (
    <React.Fragment>
      <header className="topbar">
        <nav className="navbar top-navbar navbar-expand-md navbar-light">
          <div className="navbar-header text-center">
            <Link className="navbar-brand" to="/">
              <img
                src="../assets/images/dcchail.png"
                alt="homepage"
                className="light-logo d-md-none"
                width="120"
              />
              <span>
                <img
                  src="../assets/images/dcchail.png"
                  alt="homepage"
                  className="light-logo"
                  width="140"
                />
              </span>
            </Link>
          </div>
          <div className="navbar-collapse">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <span className="nav-link sidebartoggler hidden-md-up waves-effect waves-dark">
                  <i className="sl-icon-menu"></i>
                </span>
              </li>
              <li className="nav-item">
                <span className="nav-link nav-toggler hidden-sm-down waves-effect waves-dark">
                  <i className="sl-icon-menu"></i>
                </span>
              </li>
              <li className="nav-item hidden-xs-down search-box">
                <span
                  onClick={_toggleSearch}
                  className="nav-link hidden-sm-down waves-effect waves-dark"
                >
                  <i className="icon-Magnifi-Glass2"></i>
                </span>

                {openSearch && (
                  <Search
                    toggleSearch={_toggleSearch}
                    openSearch={openSearch}
                    messages={messages}
                  />
                )}
              </li>
            </ul>
            <ul className="navbar-nav my-lg-0">
              <li
                onClick={toggleMenu}
                className={`nav-item dropdown u-pro ${
                  openedMenu ? "show" : ""
                }`}
              >
                <span
                  className="nav-link dropdown-toggle waves-effect waves-dark profile-pic"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    src={
                      user.profileImage
                        ? user.profileImage
                        : "../assets/images/icon/staff-w.png"
                    }
                    alt="user"
                    className=""
                  />
                  <span className="hidden-md-down">
                    {user.username}
                    <i className="fa fa-angle-down"></i>
                  </span>
                </span>
                <div
                  className={`dropdown-menu dropdown-menu-right animated flipInY ${
                    openedMenu ? "show" : ""
                  }`}
                >
                  <ul className="dropdown-user">
                    <li>
                      <div className="dw-user-box">
                        <div className="u-img">
                          <img
                            src={
                              user.profileImage
                                ? user.profileImage
                                : "../assets/images/icon/staff.png"
                            }
                            alt="user"
                            className=""
                          />
                          {/* <img src={user.profileImage ? user.profileImage : '../assets/images/icon/staff-w.png'} alt="user" className="" />  */}
                        </div>
                        <div className="u-text">
                          <h4>
                            {user.username && user.username.split("@")[0]}
                          </h4>
                          <p className="full-username text-muted">
                            {user.username}
                          </p>
                        </div>
                      </div>
                    </li>

                    <li className={``}>
                      <p
                        className="cursor-pointer"
                        onClick={openChangePasswordModal}
                      >
                        <i className="fa fa-shield-alt mr-2"></i>
                        <FormattedMessage {...messages.changePasswordText} />
                      </p>
                    </li>
                    <li>
                      <p className="cursor-pointer" onClick={_logout}>
                        <i className="fa fa-power-off mr-2"></i>
                        <FormattedMessage {...messages.logoutText} />
                      </p>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </header>
      <aside className="left-sidebar">
        <div className="scroll-sidebar">
          <nav className="sidebar-nav">
            <ul id="sidebarnav">
              {user.userGroupName &&
                user.userGroupName !== "User" &&
                user.userGroupName !== "Insurance" && (
                  <li
                    className={`${location.pathname === "/" ? "active" : ""}`}
                  >
                    <Link
                      className="waves-effect waves-dark"
                      to="/"
                      aria-expanded="false"
                    >
                      <i className="icon-Car-Wheel"></i>
                      <span className="hide-menu">
                        <FormattedMessage {...messages.dashboardString} />
                      </span>
                    </Link>
                  </li>
                )}
              <li
                className={`${location.pathname === "/scans" ? "active" : ""}`}
              >
                <Link
                  className="waves-effect waves-dark"
                  to="/scans"
                  aria-expanded="false"
                >
                  <i className="icon-Car-Wheel"></i>
                  <span className="hide-menu">
                    <FormattedMessage {...messages.scansString} />
                  </span>
                </Link>
              </li>
              {user.userGroupName && user.userGroupName !== "Insurance" && (
                <li>
                  <Link
                    className="has-arrow waves-effect waves-dark no-close"
                    to="#"
                    aria-expanded="false"
                  >
                    <i className="icon-Car-Wheel"></i>
                    <span className="hide-menu">
                      <FormattedMessage {...messages.settingsString} />
                    </span>
                  </Link>
                  <ul aria-expanded="false" className="collapse">
                    <li className={``}>
                      <Link
                        className={`waves-effect waves-dark ${
                          location.pathname === "/projects" ? "active" : ""
                        }`}
                        to="/projects"
                        aria-expanded="false"
                      >
                        <span className="hide-menu">
                          <FormattedMessage {...messages.projectsString} />
                        </span>
                      </Link>
                    </li>
                    {user.userGroupName &&
                      (user.userGroupName === "Super Admin" ||
                        user.userGroupName === "Admin") && (
                        <li className={``}>
                          <Link
                            className={`has-arrow waves-effect waves-dark no-close`}
                            to="#"
                            aria-expanded="false"
                          >
                            <span className="hide-menu">
                              <FormattedMessage {...messages.usersString} />
                            </span>
                          </Link>
                          <ul aria-expanded="false" className="collapse">
                            {user.userGroupName &&
                              user.userGroupName === "Super Admin" && (
                                <li className={``}>
                                  <Link
                                    className={`waves-effect waves-dark ${
                                      location.pathname === "/superadmins"
                                        ? "active"
                                        : ""
                                    }`}
                                    to="/superadmins"
                                    aria-expanded="false"
                                  >
                                    <span className="hide-menu">
                                      <FormattedMessage
                                        {...messages.superadminsUsergroupString}
                                      />
                                    </span>
                                  </Link>
                                </li>
                              )}
                            {user.userGroupName &&
                              user.userGroupName === "Super Admin" && (
                                <li className={``}>
                                  <Link
                                    className={`waves-effect waves-dark ${
                                      location.pathname === "/admins"
                                        ? "active"
                                        : ""
                                    }`}
                                    to="/admins"
                                    aria-expanded="false"
                                  >
                                    <span className="hide-menu">
                                      <FormattedMessage
                                        {...messages.adminsUsergroupString}
                                      />
                                    </span>
                                  </Link>
                                </li>
                              )}
                            <li className={``}>
                              <Link
                                className={`waves-effect waves-dark ${
                                  location.pathname === "/users" ? "active" : ""
                                }`}
                                to="/users"
                                aria-expanded="false"
                              >
                                <span className="hide-menu">
                                  <FormattedMessage
                                    {...messages.usersUsergroupString}
                                  />
                                </span>
                              </Link>
                            </li>
                            {user.userGroupName &&
                              user.userGroupName === "Super Admin" && (
                                <li className={``}>
                                  <Link
                                    className={`waves-effect waves-dark ${
                                      location.pathname === "/insuranceusers"
                                        ? "active"
                                        : ""
                                    }`}
                                    to="/insuranceusers"
                                    aria-expanded="false"
                                  >
                                    <span className="hide-menu">
                                      <FormattedMessage
                                        {...messages.insurancesUsergroupString}
                                      />
                                    </span>
                                  </Link>
                                </li>
                              )}
                          </ul>
                        </li>
                      )}
                    <li className={``}>
                      <Link
                        className={`waves-effect waves-dark ${
                          location.pathname === "/scanners" ? "active" : ""
                        }`}
                        to="/scanners"
                        aria-expanded="false"
                      >
                        <span className="hide-menu">
                          <FormattedMessage {...messages.scannersString} />
                        </span>
                      </Link>
                    </li>
                    <li className={``}>
                      <Link
                        className={`waves-effect waves-dark ${
                          location.pathname === "/insurances" ? "active" : ""
                        }`}
                        to="/insurances"
                        aria-expanded="false"
                      >
                        <span className="hide-menu">
                          <FormattedMessage {...messages.insurancesString} />
                        </span>
                      </Link>
                    </li>
                    <li className={``}>
                      <Link
                        className={`waves-effect waves-dark ${
                          location.pathname === "/cars" ? "active" : ""
                        }`}
                        to="/cars"
                        aria-expanded="false"
                      >
                        <span className="hide-menu">
                          <FormattedMessage {...messages.carsString} />
                        </span>
                      </Link>
                    </li>
                    {/* <li className={``}> 
                    <Link className={`waves-effect waves-dark ${location.pathname === "/colors" ? "active" : ""}`} to="/colors" aria-expanded="false"><span className="hide-menu">
                      <FormattedMessage {...messages.colorsString} />
                    </span></Link>
                  </li> */}
                  </ul>
                </li>
              )}
            </ul>
          </nav>
        </div>
        <ChangePasswordModal
          messages={userMessages}
          closeModal={openChangePasswordModal}
          openModal={isChangePasswordModalOpen}
          updatePassword={_updatePassword}
        />
      </aside>
    </React.Fragment>
  );
};

export default injectIntl(Sidebar);
