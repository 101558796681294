import React, { useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import "rc-pagination/assets/index.css";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import messages from "./languages";
import { editScan } from "../../actions/scansActions";
import { getUserData } from "../../selectors/userSelector";
import { getInsurancesSelector } from "../../selectors/insuranceSelector";
import { getInsurances } from "../../actions/insurancesActions";
import Pagination from "../../components/Pagination";

const Table = ({
  intl,
  displayAction,
  fields,
  dataArr,
  tableHeading,
  editAction,
  deleteAction,
  count,
  currentPage,
  pageChange,
  limit,
  textfields,
  showPageInput = false,
}) => {
  const dispatch = useDispatch();

  const insurances = useSelector(getInsurancesSelector);
  const user = useSelector(getUserData);
  const userIsAdmin =
    user.userGroupName &&
    (user.userGroupName === "Super Admin" || user.userGroupName === "Admin");

  const mountFunction = () => dispatch(getInsurances("", intl));
  useEffect(mountFunction, []);

  const changeScanInsurance = (data) => {
    if (data.scan.id) {
      data.scan.insuranceId = data.insurance.id;
      data.scan.insuranceCode = data.insurance.code;
      dispatch(
        editScan(
          {
            insuranceId: data.insurance.id,
            insuranceCode: data.insurance.code,
          },
          data.scan.id,
          intl
        )
      );
    }
  };

  const renderSingleHeading = (heading, index) => {
    return (
      <th
        key={index}
        scope="col"
        data-tablesaw-sortable-col
        data-tablesaw-priority="persist"
        className="border"
      >
        {heading.toUpperCase()}
      </th>
    );
  };

  const renderSingleCell = (row) => (cell, index) => {
    let field = row[cell];
    if (cell === "insuranceCode") {
      const options = insurances.map((i) => ({
        label: i.name,
        value: i.code,
        scan: row,
        insurance: i,
      }));
      const selectedOption = field
        ? options.find((c) => c.value === field)
        : undefined;
      if (userIsAdmin) {
        return (
          <td key={index} className=" ">
            <Select
              placeholder={intl.formatMessage(messages.selectInsurance)}
              value={selectedOption}
              onChange={changeScanInsurance}
              options={options}
            />
          </td>
        );
      }
      field = selectedOption ? selectedOption.label : "";
    } else if (typeof field !== "object") {
      let dateCells = ["createdAt", "finishedDate", "synchronizedDate"];
      if (
        dateCells.find((c) => {
          return c === cell;
        })
      ) {
        field = moment(field).format("DD-MM-YYYY HH:MM");
      }
    } else {
      if (field && field.length > 0) {
        field = row[cell].map((r) => {
          return `${r.name}, `;
        });
      } else {
        field = "";
      }
    }

    return (
      <td
        key={index}
        className=" "
        onClick={
          displayAction || editAction
            ? displayAction
              ? displayAction(row)
              : editAction(row)
            : null
        }
      >
        {field}
      </td>
    );
  };

  const renderSingleRow = (row, index) => {
    return (
      <tr key={index}>
        {fields && fields.length > 0
          ? fields.map(renderSingleCell(row))
          : Object.keys(row).map(renderSingleCell(row))}
        {displayAction && (
          <td className="text-center">
            <button
              onClick={displayAction(row)}
              type="button"
              className="mr-2 btn btn-info btn-circle"
            >
              <i className="fa fa-search-plus"></i>{" "}
            </button>
          </td>
        )}
        {editAction && (
          <td className="text-center">
            <button
              onClick={editAction(row)}
              type="button"
              className="mr-2 btn btn-info btn-circle"
            >
              <i className="fa fa-pencil-alt"></i>{" "}
            </button>
          </td>
        )}
        {deleteAction && (
          <td className="text-center">
            <button
              onClick={deleteAction(row.id)}
              type="button"
              className="btn btn-warning btn-circle"
            >
              <i className="fa fa-times"></i>{" "}
            </button>
          </td>
        )}
      </tr>
    );
  };

  const _pageChange = (page) => {
    pageChange(page);
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">{tableHeading}</h4>
            <table
              className="tablesaw table-bordered table-hover table no-wrap"
              data-tablesaw-mode="swipe"
              data-tablesaw-sortable
              data-tablesaw-sortable-switch
              data-tablesaw-minimap
              data-tablesaw-mode-switch
            >
              <thead>
                <tr>
                  {textfields && textfields.length > 0
                    ? textfields.map(renderSingleHeading)
                    : Object.keys(dataArr[0] ? dataArr[0] : []).map(
                        renderSingleHeading
                      )}
                  {displayAction && (
                    <th
                      scope="col"
                      data-tablesaw-sortable-col
                      data-tablesaw-priority="persist"
                      className="border text-center"
                    >
                      DETAILS
                    </th>
                  )}
                  {editAction && (
                    <th
                      scope="col"
                      data-tablesaw-sortable-col
                      data-tablesaw-priority="persist"
                      className="border text-center"
                    >
                      EDIT
                    </th>
                  )}
                  {deleteAction && (
                    <th
                      scope="col"
                      data-tablesaw-sortable-col
                      data-tablesaw-priority="persist"
                      className="border text-center"
                    >
                      DELETE
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>{dataArr.map(renderSingleRow)}</tbody>
            </table>
            <Pagination
              defaultPageSize={parseInt(limit)}
              current={parseInt(currentPage)}
              pageSize={parseInt(limit)}
              onChange={_pageChange}
              total={count}
              showPageInput={showPageInput}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Table.propTypes = {
  fields: PropTypes.array,
  tableHeading: PropTypes.string,
  dataArr: PropTypes.array.isRequired,
  editAction: PropTypes.func,
  deleteAction: PropTypes.func,
  showPageInput: PropTypes.bool,
};

export default injectIntl(Table);
