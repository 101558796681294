import { defineMessages } from 'react-intl';

export const scope = 'app.components.Common';

export default defineMessages({
  searchPlaceholder: {
    id: `${scope}.searchPlaceholder`,
    defaultMessage: 'Išči'
  },
  logoutText: {
    id: `${scope}.logoutText`,
    defaultMessage: 'Odjava'
  },
  projectsString: {
    id: `${scope}.projectsString`,
    defaultMessage: 'Projekte'
  },
  usersString: {
    id: `${scope}.usersString`,
    defaultMessage: 'Uporabniki'
  },
  scannersString: {
    id: `${scope}.scannersString`,
    defaultMessage: 'Sekenrje'
  },
  insurancesString: {
    id: `${scope}.insurancesString`,
    defaultMessage: 'Zavarovanj'
  },
  menuString: {
    id: `${scope}.menuString`,
    defaultMessage: 'Meni'
  },
  carsString: {
    id: `${scope}.carsString`,
    defaultMessage: 'Avte'
  },
  colorsString: {
    id: `${scope}.colorsString`,
    defaultMessage: 'Barve'
  },
  settingsString: {
    id: `${scope}.settingsString`,
    defaultMessage: 'Nastavitve'
  },
  dashboardString: {
    id: `${scope}.dashboardString`,
    defaultMessage: 'Dashboard'
  },
  scansString: {
    id: `${scope}.scansString`,
    defaultMessage: 'Scans'
  },
  superadminsUsergroupString: {
    id: `${scope}.superadminsUsergroupString`,
    defaultMessage: 'Super admins'
  },
  adminsUsergroupString: {
    id: `${scope}.adminsUsergroupString`,
    defaultMessage: 'Admins'
  },
  usersUsergroupString: {
    id: `${scope}.usersUsergroupString`,
    defaultMessage: 'Users'
  },
  insurancesUsergroupString: {
    id: `${scope}.insurancesUsergroupString`,
    defaultMessage: 'Insurance users'
  },
  changePasswordText: {
    id: `${scope}.changePasswordText`,
    defaultMessage: 'Change Password'
  },
});