import React, { useEffect } from "react";
import messages from "./languages";
import LoginForm from "../../components/LoginForm";
import { useDispatch } from "react-redux";
import { login } from "../../actions/authActions";
import { injectIntl } from "react-intl";

const LoginPage = ({ intl, location }) => {
  const dispatch = useDispatch();
  const mountFunction = () => {
    let l = location;
  };

  useEffect(mountFunction, []);

  const submitLogin = (data) => {
    dispatch(login(data, intl));
  };

  return (
    <section
      id="wrapper"
      className="login-register login-sidebar"
      style={{ backgroundImage: `url(../assets/images/avtocar.jpg)` }}
    >
      <div className="login-box card">
        <div className="card-body">
          <LoginForm submitLogin={submitLogin} messages={messages} />
        </div>
      </div>
    </section>
  );
};

export default injectIntl(LoginPage);
