import { defineMessages } from 'react-intl';

export const scope = 'app.components.ScansTable';

export default defineMessages({
  selectInsurance: {
    id: `${scope}.selectInsurance`,
    defaultMessage: 'Izberite zavarovalnico',
  },
});
