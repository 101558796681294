import React, { useState } from "react";
import Modal from "react-modal";
import qs from "query-string";
import { useDispatch } from "react-redux";
import { Field, reduxForm } from "redux-form/immutable";
import Select from "react-select";
import { DateRangeInput } from "@blueprintjs/datetime";
import moment from "moment";

import { FormattedMessage, injectIntl } from "react-intl";

import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import { otherModalStyles, LOADING } from "../../../constants";

const ExportScanForm = ({
  insurances,
  openModal,
  closeModal,
  handleSubmit,
  messages,
  intl,
  exportScans,
  user,
}) => {
  const dispatch = useDispatch();
  const [startDateFrom, setstartDateFrom] = useState(null);
  const [startDateTo, setstartDateTo] = useState(null);

  const submitF = async (data) => {
    data = data.toJS();

    let fdata = {};
    if (startDateFrom) {
      fdata.startDateFrom = moment(startDateFrom).format("YYYY-MM-DD HH:MM:S");
    }
    if (startDateTo) {
      fdata.startDateTo = moment(startDateTo).format("YYYY-MM-DD HH:MM:S");
    }

    if (data.insuranceId && data.insuranceId.value) {
      fdata.insurance = data.insuranceId.value;
    } else {
      if (user.insuranceId) {
        fdata.insurance = user.insuranceId;
      }
    }

    fdata.sort = "synchronizedDate-desc";
    fdata.export = "export";

    fdata = qs.stringify(fdata);

    dispatch({ type: LOADING, payload: true });

    exportScans(fdata);
    closeModal();
  };

  const handleChangeStart = (dates) => {
    var dateFrom = new Date(dates[0]);
    var dateTo = new Date(dates[1]);

    setstartDateFrom(dateFrom);
    setstartDateTo(dateTo);
  };

  return (
    <Modal
      isOpen={openModal}
      contentLabel="Export scans"
      onRequestClose={closeModal}
      ariaHideApp={false}
      style={otherModalStyles}
    >
      <div className="modal-wrapper">
        <div className="card-header bg-info">
          <h4 className="m-b-0 text-white">
            <FormattedMessage {...messages.exportScan} />
          </h4>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit(submitF)}>
            <div className="form-body">
              <h3 className="card-title">
                <FormattedMessage {...messages.exportScansSubtitle} />
              </h3>
              <hr></hr>
              <div className="row p-t-20 p-l-20">
                <Field
                  name="period"
                  label={intl.formatMessage(messages.period)}
                  component={renderDateRangeField}
                  errorText={intl.formatMessage(messages.errorText)}
                  handleChangeStart={handleChangeStart}
                  startDateFrom={startDateFrom}
                  startDateTo={startDateTo}
                  type="text"
                />
              </div>
              <div className="row p-t-20 p-l-10">
                {user.userGroupName && user.userGroupName !== "Insurance" && (
                  <Field
                    label={intl.formatMessage(messages.insuranceLabel)}
                    options={insurances.map((c) => {
                      return { label: c.name, value: c.code };
                    })}
                    name="insuranceId"
                    multi={false}
                    component={renderSelect}
                    errorText={intl.formatMessage(messages.errorText)}
                    placeholder={intl.formatMessage(
                      messages.insurancesPlaceholder
                    )}
                  />
                )}
              </div>
            </div>
            <div className="text-right">
              <button type="submit" className="btn btn-success">
                <i className="fa fa-download"></i> Export
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

const renderDateRangeField = ({
  input,
  startDateFrom,
  startDateTo,
  handleChangeStart,
  errorText,
  label,
  type,
  meta: { touched, error, warning, valid },
}) => (
  <div className={`form-group ${touched && error && "has-danger"}`}>
    <label className="control-label">{label}</label>
    <div>
      <DateRangeInput
        {...input}
        className="date-range-modal"
        formatDate={(date) => (date == null ? "" : date.toLocaleDateString())}
        allowSingleDayRange={true}
        onChange={handleChangeStart}
        parseDate={(str) => new Date(Date.parse(str))}
        closeOnSelection={false}
        value={[startDateFrom, startDateTo]}
      />
    </div>
    {touched && error && (
      <span className="form-control-feedback">{errorText}</span>
    )}
  </div>
);

const renderSelect = ({
  multi,
  input,
  placeholder,
  options,
  label,
  palceholder,
  errorText,
  warningText,
  type,
  meta: { touched, error, warning, valid },
}) => {
  return (
    <div className="col-md-4">
      <div className={`form-group ${touched && error && "has-danger"}`}>
        <label className="control-label">{label}</label>
        <Select
          placeholder={placeholder}
          value={input.value}
          {...input}
          onBlur={() => {
            input.onBlur(input.value);
          }}
          options={options}
          isMulti={multi}
        />
        {touched && error && (
          <span className="form-control-feedback">{errorText}</span>
        )}
      </div>
    </div>
  );
};

// const validate = (values) => {
//     values = values.toJS();
//     let errors = {};

//     if (!values.period) {
//         errors.period = true
//     }

//     return errors
// }

export default reduxForm({
  enableReinitialize: true,
  form: "ExportScanForm",
})(injectIntl(ExportScanForm));
