import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  addFollowers,
  getScan,
  getScanFileData,
  getScanSnapshotData
} from "../../../actions/scansActions";
import { getScanSelector } from "../../../selectors/scansSelector";
import messages from "../languages";
import { Img } from "react-image";
import { FormattedMessage, injectIntl } from "react-intl";
import FollowersModal from "../../../components/FollowersModal";
import ModalVideo from "../../../components/PlayerModal";
import Carousel, { Modal, ModalGateway } from "react-images";

const ScanDetailsPage = ({ location, intl, match }) => {
  const [currentImage, setCurrentImage] = useState(null);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [isFollowersModalOpen, setisFollowersModalOpen] = useState(false);
  const [isPlayerModalOpen, setisPlayerModalOpen] = useState(false);
  const [videoURL, setvideoURL] = useState(null);
  const thumbnails = ["Top", "Left", "Right", "Left Rail", "Right Rail"];

  const dispatch = useDispatch();

  const scan = useSelector(getScanSelector);
  // const i_loading = useSelector(selectILoading);

  const reportFiles = scan.Files.filter((f) => {
    return f.type.toLowerCase() === "report";
  });

  const videoFiles = scan.Files.filter((f) => {
    return f.type.toLowerCase() === "video";
  });

  const openLightbox = (snap) => () => {
    getScanSnapshotData(snap.scanId, snap.id).then((response) => {
      if (response.status === 200) {
        setCurrentImage(response.data.link);
        setViewerIsOpen(true);
      }
    });
  };

  const closeLightbox = () => {
    setCurrentImage(null);
    setViewerIsOpen(false);
  };

  const mountFunction = () => {
    window.scrollTo(0, 0);

    dispatch(getScan(match.params.id, intl));
  };

  useEffect(mountFunction, []);

  const getVideoStreamFileId = (videoFiles, title) => {
    var url = videoFiles.filter((element) => {
      var splited = element.location.split("/");
      var file = splited[splited.length - 1];
      file = file.split(".");
      var fileName = file[0];

      return fileName === title;
    });

    if (url[0]) {
      return url[0].id;
    }
  };

  const playVideo = async (videoFileId) => {
    getScanFileData(videoFileId).then((response) => {
      if (response.status === 200) {
        setvideoURL(response.data.link);
        setisPlayerModalOpen(true);
      }
    });
  };

  const closeVideoModal = () => {
    setisPlayerModalOpen(false);
    setvideoURL(null);
  };

  const closeFModal = () => {
    setisFollowersModalOpen(false);
  };

  const openFModal = () => {
    setisFollowersModalOpen(true);
  };

  const addNewFollowers = (data) => {
    data = data
      .filter((fd) => {
        return typeof fd.id === "undefined";
      })
      .map((d) => {
        return { email: d.email, scanId: match.params.id };
      });
    if (data.length > 0) {
      dispatch(addFollowers(data, match.params.id, intl));
    }
  };

  // const _sendEmail = () => {
  //   dispatch(sendEmail(match.params.id, intl));
  // };

  const openReportFile = (file) => () => {
    getScanFileData(file.id).then((response) => {
      if (response.status === 200) {
        const link = document.createElement('a');
        link.href = response.data.link;
        link.download = "";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  };

  return (
    <div className="page-wrapper h-100">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header bg-info">
                <h4 className="m-b-0 text-white">
                  <FormattedMessage {...messages.stitle} />
                  &nbsp;
                  {scan.Car && scan.Car.licensePlate}
                </h4>
              </div>
              <div className="card-body">
                <div className="form-horizontal" role="form">
                  <div className="form-body">
                    {/* Scan */}
                    <h3 className="box-title">
                      <FormattedMessage {...messages.scanString} />
                    </h3>
                    <hr className="m-t-0 m-b-40"></hr>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="control-label text-left text-md-left col-md-3">
                            <FormattedMessage {...messages.createdAtString} />:
                          </label>
                          <div className="col-md-9">
                            <p className="form-control-static">
                              {" "}
                              {moment(scan.createdAt).format(
                                "DD-MM-YYYY HH:MM"
                              )}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="control-label text-left text-md-left col-md-3">
                            <FormattedMessage
                              {...messages.finishedDateString}
                            />
                            :
                          </label>
                          <div className="col-md-9">
                            <p className="form-control-static">
                              {" "}
                              {moment(scan.finishedDate).format(
                                "DD-MM-YYYY HH:MM"
                              )}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="control-label text-left text-md-left col-md-3">
                            <FormattedMessage {...messages.companyLabel} />:
                          </label>
                          <div className="col-md-9">
                            <p className="form-control-static">
                              {" "}
                              {scan.Company && scan.Company.name}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Car */}
                    <h3 className="box-title">
                      <FormattedMessage {...messages.carString} />
                    </h3>
                    <hr className="m-t-0 m-b-40"></hr>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="control-label text-left text-md-left col-md-3">
                            <FormattedMessage {...messages.carBrandString} />:
                          </label>
                          <div className="col-md-9">
                            <p className="form-control-static">
                              {" "}
                              {scan.Car && scan.Car.brand}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="control-label text-left text-md-left col-md-3">
                            <FormattedMessage {...messages.carModelString} />:
                          </label>
                          <div className="col-md-9">
                            <p className="form-control-static">
                              {" "}
                              {scan.Car && scan.Car.model}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="control-label text-left text-md-left col-md-3">
                            <FormattedMessage
                              {...messages.licensePlateString}
                            />
                            :
                          </label>
                          <div className="col-md-9">
                            <p className="form-control-static">
                              {" "}
                              {scan.Car && scan.Car.licensePlate}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="control-label text-left text-md-left col-md-3">
                            <FormattedMessage {...messages.doorCountString} />:
                          </label>
                          <div className="col-md-9">
                            <p className="form-control-static">
                              {" "}
                              {scan.Car && scan.Car.doorCount}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Customer */}
                    <h3 className="box-title">
                      <FormattedMessage {...messages.customerString} />
                    </h3>
                    <hr className="m-t-0 m-b-40"></hr>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="control-label text-left text-md-left col-md-3">
                            <FormattedMessage {...messages.countryString} />:
                          </label>
                          <div className="col-md-9">
                            <p className="form-control-static">
                              {" "}
                              {scan.Customer && scan.Customer.country}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="control-label text-left text-md-left col-md-3">
                            <FormattedMessage {...messages.nameString} />:
                          </label>
                          <div className="col-md-9">
                            <p className="form-control-static">
                              {" "}
                              {scan.Customer && scan.Customer.firstName}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="control-label text-left text-md-left col-md-3">
                            <FormattedMessage {...messages.lastnameString} />:
                          </label>
                          <div className="col-md-9">
                            <p className="form-control-static">
                              {" "}
                              {scan.Customer && scan.Customer.lastName}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="control-label text-left text-md-left col-md-3">
                            <FormattedMessage {...messages.emailString} />:
                          </label>
                          <div className="col-md-9">
                            <p className="form-control-static">
                              {" "}
                              {scan.Customer && scan.Customer.email}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="control-label text-left text-md-left col-md-3">
                            <FormattedMessage {...messages.telephoneString} />:
                          </label>
                          <div className="col-md-9">
                            <p className="form-control-static">
                              {" "}
                              {scan.Customer && scan.Customer.phoneNumber}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Reports */}
                    <h3 className="box-title">
                      <FormattedMessage {...messages.reportString} />
                    </h3>
                    <hr className="m-t-0 m-b-40"></hr>
                    <div className="row">
                      {reportFiles.length > 0 ? (
                        reportFiles.map((file, i) => (
                          <div key={i} className="col-md-2 text-center cursor-pointer">
                            <span onClick={openReportFile(file)}>
                              <img
                                src="../assets/images/word-icon2.png"
                                width="100"
                                alt={`report-${i + 1}`} />
                              <p className="text-center">Report {i + 1}</p>
                            </span>
                          </div>
                        ))
                      ) : (
                        <div className="col-md-6">
                          <div className="form-group ">
                            <label className="form-control-static">
                              {" "}
                              <FormattedMessage {...messages.noreportString} />
                            </label>
                          </div>
                        </div>
                      )}
                    </div>
                    <hr className="m-t-0 m-b-40"></hr>

                    {/* Followers */}
                    <h3 className="box-title">
                      <FormattedMessage {...messages.followersString} />
                      <button
                        onClick={openFModal}
                        className="ml-5 btn btn-rounded btn-success"
                      >
                        <FormattedMessage {...messages.addFString} />
                      </button>
                      {/* <button onClick={_sendEmail} className="ml-5 btn btn-rounded btn-success"><FormattedMessage {...messages.sendMailString} /></button> */}
                    </h3>
                    <hr className="m-t-0 m-b-40"></hr>
                    <div className="row">
                      {scan.Followers.length > 0 ? (
                        scan.Followers.map((f, i) => {
                          return (
                            <div key={i} className="col-md-6">
                              <div className="form-group">
                                <label className="control-label text-right">
                                  {f.email}
                                </label>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="col-md-6">
                          <div className="form-group ">
                            <label className="form-control-static">
                              {" "}
                              <FormattedMessage
                                {...messages.nofollowersString}
                              />
                            </label>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* Video */}
                    <h3 className="box-title">
                      <FormattedMessage {...messages.videoString} />
                    </h3>
                    <hr className="m-t-0 m-b-40"></hr>
                    <div className="row m-b-40">
                      {thumbnails.map((title, index) => {
                        var thumbnail_title = title.replace(/ /g, "");

                        var fileId = getVideoStreamFileId(
                          videoFiles,
                          thumbnail_title
                        );

                        return (
                          <div className="col-md-6 col-lg-4 mt-4" key={index}>
                            <h3 className="box-title">{title} </h3>
                            <Img
                              src="../assets/images/avtocar.jpg"
                              loader={
                                <img
                                  src="../assets/images/background/black-background.jpg"
                                  alt="loading"
                                />
                              }
                              unloader={"sdvsdvdc"}
                              width="100%"
                              height="auto"
                              container={(children) => {
                                return (
                                  <div className="video-thumbnail mt-2">
                                    {children}
                                    <div
                                      className="overlay"
                                      onClick={() => playVideo(fileId)}
                                    >
                                      <i className="far fa-play-circle overlay icon"></i>
                                    </div>
                                  </div>
                                );
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                    {/* Snapshots */}
                    <h3 className="box-title m-t-40">
                      <FormattedMessage {...messages.ssnapshotString} />
                    </h3>
                    <hr className="m-t-0 m-b-40"></hr>
                    {scan.Snapshots.length > 0 && (
                      <div className="row">
                        {/* <Snapshot /> */}
                        {scan.Snapshots && scan.Snapshots.map((snap, index) => (
                          <div key={index} className="col-md-4 cursor-pointer mb-4">
                            <img
                              onClick={openLightbox(snap)}
                              alt={`snapshot-${index + 1}`}
                              className="w-100"
                              src="../assets/images/avtocar.jpg" />
                          </div>
                        ))}
                        {viewerIsOpen && (
                          <ModalGateway>
                            <Modal onClose={closeLightbox}>
                              <Carousel
                                currentIndex={0}
                                views={[{source: currentImage, caption: "snapshot"}]}
                              />
                            </Modal>
                          </ModalGateway>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isFollowersModalOpen && (
        <FollowersModal
          initialValues={{ followers: scan.Followers }}
          addNewFollowers={addNewFollowers}
          openModal={isFollowersModalOpen}
          closeModal={closeFModal}
          messages={messages}
        />
      )}
      {isPlayerModalOpen && (
        <ModalVideo
          openModal={isPlayerModalOpen}
          closeModal={closeVideoModal}
          scanId={match.params.id}
          messages={messages}
          video_link={videoURL}
        />
      )}
    </div>
  );
};

export default injectIntl(ScanDetailsPage);
