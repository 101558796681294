import { defineMessages } from 'react-intl';

export const scope = 'app.components.Users';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Seznam uporabnikov'
  },
  searchString: {
    id: `${scope}.searchString`,
    defaultMessage: 'Vpišite iskalni niz'
  },
  new: {
    id: `${scope}.new`,
    defaultMessage: 'Novi uporabnik'
  },
  searchButton: {
    id: `${scope}.searchButton`,
    defaultMessage: 'Išči'
  },
  usersString: {
    id: `${scope}.usersString`,
    defaultMessage: 'Uporabnike'
  },
  newUser: {
    id: `${scope}.newUser`,
    defaultMessage: 'Dodaj uporabnik'
  },
  changePassword: {
    id: `${scope}.changePassword`,
    defaultMessage: 'Change Password'
  },
  newPassword: {
    id: `${scope}.newPassword`,
    defaultMessage: 'New Password'
  },
  confirmPassword: {
    id: `${scope}.changePassword`,
    defaultMessage: 'Confirm Password'
  },
  errorText: {
    id: `${scope}.errorText`,
    defaultMessage: `Obvezno polje`,
  },
  namePlaceholder: {
    id: `${scope}.namePlaceholder`,
    defaultMessage: `Vpišite uporabniško ime`
  },
  companyLabel: {
    id: `${scope}.companyLabel`,
    defaultMessage: `Podjetje`
  },
  companyPlaceholder: {
    id: `${scope}.companyPlaceholder`,
    defaultMessage: `Izberite podjetje`
  },
  nameLabel: {
    id: `${scope}.nameLabel`,
    defaultMessage: `Uporabniško ime`
  },
  passwordLabel: {
    id: `${scope}.passwordLabel`,
    defaultMessage: `Geslo`
  },
  passwordPlaceholder: {
    id: `${scope}.passwordPlaceholder`,
    defaultMessage: `Vpišite geslo`
  },
  newSubtitle: {
    id: `${scope}.newSubtitle`,
    defaultMessage: `Vnesite podatke`
  },
  userGroupLabel: {
    id: `${scope}.userGroupLabel`,
    defaultMessage: `Skupina`
  },
  userGroupPlaceholder: {
    id: `${scope}.userGroupPlaceholder`,
    defaultMessage: `Izberite skupino`
  },
  deleteString: {
    id: `${scope}.deleteString`,
    defaultMessage: `Ali ste sigurni da hočete zbrisat?`
  },
  imageString: {
    id: `${scope}.imageString`,
    defaultMessage: `Profile Image`
  },
  addimageString: {
    id: `${scope}.addimageString`,
    defaultMessage: "Drag 'n' drop some files here, or click to select files"
  },
  edit: {
    id: `${scope}.edit`,
    defaultMessage: "Edit user"
  },
  insuranceLabel: {
    id: `${scope}.insuranceLabel`,
    defaultMessage: `Zavarovanja`
  },
  insurancesPlaceholder: {
    id: `${scope}.insurancesPlaceholder`,
    defaultMessage: `Izberite zavarovanje`
  },
  superadminsUsergroupString: {
    id: `${scope}.superadminsUsergroupString`,
    defaultMessage: 'Super admins'
  },
  adminsUsergroupString: {
    id: `${scope}.adminsUsergroupString`,
    defaultMessage: 'Admins'
  },
  usersUsergroupString: {
    id: `${scope}.usersUsergroupString`,
    defaultMessage: 'Users'
  },
  insurancesUsergroupString: {
    id: `${scope}.insurancesUsergroupString`,
    defaultMessage: 'Insurance users'
  }
});