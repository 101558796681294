import React, { useEffect, useState, useRef } from "react";
import ReactPlayer from "react-player";

const VideoPlayer = ({ videoLink, videoState }) => {
  const videoWrap = useRef(null);

  const [videoPlaying, setvideoPlaying] = useState(true);
  const [videoPlaybackRate, setvideoPlaybackRate] = useState(0.5);

  const handleScrollSeek = async (e) => {
    if (e.deltaY > 0) {
      //scrolling down
      await videoWrap.current.seekTo(
        videoWrap.current.getCurrentTime() - 0.1,
        "seconds"
      );
    } else if (e.deltaY < 0) {
      //scrolling up
      await videoWrap.current.seekTo(
        videoWrap.current.getCurrentTime() + 0.1,
        "seconds"
      );
    }
    setvideoPlaying(false);
  };

  const mountFunction = () => {
    videoState(true);
  };

  useEffect(mountFunction, []);

  return (
    <React.Fragment>
      <div
        className="row mr-0 ml-0 player-main-wrap"
        onWheel={(e) => handleScrollSeek(e)}
      >
        <div className="col-md-12 pr-0 pl-0 ">
          {!videoLink && (
            <div className="video-player-wrapper">
              <img
                width="50"
                src="../assets/images/loading-gif.gif"
                alt="loading"
              />
            </div>
          )}
          {videoLink && (
            <ReactPlayer
              ref={videoWrap}
              url={videoLink}
              controls={true}
              height="100%"
              playbackRate={videoPlaybackRate}
              progressInterval={0.00001}
              width="100%"
              playing={videoPlaying}
              onSeek={function name(e) {
                setvideoPlaybackRate(0.1);
                setvideoPlaying(false);
              }}
              onPlay={function name(e) {
                setvideoPlaying(true);
                setvideoPlaybackRate(0.5);
              }}
              config={{
                file: {
                  attributes: {
                    crossOrigin: "false",
                  },
                },
              }}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default VideoPlayer;
