import { combineReducers } from 'redux-immutable'
import {reducer as formReducer} from 'redux-form/immutable'
import { connectRouter } from 'connected-react-router/immutable'
import languageProviderReducer from '../components/LanguageProvider/reducer';
import authReducer from "./authReducer"
import mainReducer from "./mainReducer"
import userReducer from "./userReducer"
import projectsReducer from "./projectsReducer"
import countriesReducer from "./countriesReducer"
import companiesReducer from "./companyReducer"
import dentsReducer from "./dentReducer"
import scannersReducer from "./scannersReducer"
import insurancesReducer from "./insurancesReducer"
import carsReducer from "./carsReducer"
import colorsReducer from "./colorsReducer";
import dashboardReducer from "./dashboardReducer"
import scansReducer from "./scansReducer"

const rootReducer = (history) => combineReducers({
  form: formReducer,
  language: languageProviderReducer,
  router: connectRouter(history),
  auth: authReducer,
  main: mainReducer,
  user: userReducer,
  projects: projectsReducer,
  countries: countriesReducer,
  companies: companiesReducer,
  dents: dentsReducer,
  scanners: scannersReducer,
  insurances: insurancesReducer,
  cars: carsReducer,
  colors: colorsReducer,
  dashboard: dashboardReducer,
  scans: scansReducer
});

export default rootReducer;