import React, { useState } from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { Field, reduxForm } from "redux-form/immutable";
import { FormattedMessage, injectIntl } from "react-intl";
import { LOADING, otherModalStyles } from "../../../constants";
import { validatePassword } from "../../../helper/validationHelper";

const NewUserForm = ({
  userGroups,
  companies,
  insurances,
  openModal,
  closeModal,
  handleSubmit,
  messages,
  intl,
  addNew,
  loadXHR,
  user,
  originPage,
}) => {
  const dispatch = useDispatch();
  const [groupSelected, setGroupSelected] = useState(null);
  const [groupSelectedId, setGroupSelectedId] = useState(null);

  var us = userGroups;

  if (user.userGroupName !== "Super Admin") {
    us = userGroups.filter((u) => {
      return (
        u.name !== "Super Admin" && u.name !== "Insurance" && u.name !== "Admin"
      );
    });
  }

  const submitF = (data) => {
    data = data.toJS();

    let fdata = {};
    if (data.companyId && data.companyId.value) {
      fdata.companyId = data.companyId.value;
    } else {
      fdata.companyId = null;
    }
    fdata.username = data.username;
    fdata.userGroupId = groupSelectedId;
    fdata.originPage = originPage;

    if (data.password) {
      fdata.password = data.password;
    }
    if (data.insuranceId && data.insuranceId.value) {
      fdata.insuranceId = data.insuranceId.value;
    } else {
      fdata.insuranceId = null;
    }

    // Set users companyId if nothing is submitted
    if (!fdata.companyId && !fdata.insuranceId) {
      fdata.companyId = user.companyId;
    }

    addNew(fdata);
    closeModal();
  };

  const onGroupSelect = (group) => {
    setGroupSelected(group.label);
    setGroupSelectedId(group.value);
  };

  return (
    <Modal
      isOpen={openModal}
      contentLabel="New project"
      onRequestClose={closeModal}
      ariaHideApp={false}
      style={otherModalStyles}
    >
      <div className="modal-wrapper">
        <div className="card-header bg-info">
          <h4 className="m-b-0 text-white">
            <FormattedMessage {...messages.new} />
          </h4>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit(submitF)}>
            <div className="form-body">
              <h3 className="card-title">
                <FormattedMessage {...messages.newSubtitle} />
              </h3>
              <hr></hr>
              <div className="row p-t-20">
                <Field
                  name="username"
                  label={intl.formatMessage(messages.nameLabel)}
                  component={renderField}
                  errorText={intl.formatMessage(messages.errorText)}
                  palceholder={intl.formatMessage(messages.namePlaceholder)}
                  type="text"
                />

                <Field
                  label={intl.formatMessage(messages.userGroupLabel)}
                  options={us.map((c) => {
                    return { label: c.name, value: c.id };
                  })}
                  name="userGroupId"
                  multi={false}
                  component={renderSelect}
                  errorText={intl.formatMessage(messages.errorText)}
                  placeholder={intl.formatMessage(
                    messages.userGroupPlaceholder
                  )}
                  onChange={onGroupSelect}
                />

                {user.userGroupName &&
                  user.userGroupName === "Super Admin" &&
                  groupSelected &&
                  groupSelected !== "Insurance" &&
                  groupSelected !== "Super Admin" && (
                    <Field
                      label={intl.formatMessage(messages.companyLabel)}
                      options={companies.map((c) => {
                        return { label: c.name, value: c.id };
                      })}
                      name="companyId"
                      multi={false}
                      component={renderSelect}
                      errorText={intl.formatMessage(messages.errorText)}
                      placeholder={intl.formatMessage(
                        messages.companyPlaceholder
                      )}
                    />
                  )}

                {user.userGroupName &&
                  user.userGroupName === "Super Admin" &&
                  groupSelected &&
                  groupSelected === "Insurance" && (
                    <Field
                      label={intl.formatMessage(messages.insuranceLabel)}
                      options={insurances.map((c) => {
                        return { label: c.name, value: c.id };
                      })}
                      name="insuranceId"
                      multi={false}
                      component={renderSelect}
                      errorText={intl.formatMessage(messages.errorText)}
                      placeholder={intl.formatMessage(
                        messages.insurancesPlaceholder
                      )}
                    />
                  )}

                <Field
                  name="password"
                  label={intl.formatMessage(messages.passwordLabel)}
                  component={renderField}
                  palceholder={intl.formatMessage(messages.passwordPlaceholder)}
                  type="password"
                />

                <Field
                  name="confirm_password"
                  label="Confirm Password"
                  component={renderField}
                  type="password"
                  intl={intl}
                />
              </div>
            </div>
            <div className="text-right">
              <button type="submit" className="btn btn-success">
                <i className="fa fa-check"></i> Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
const renderField = ({
  input,
  label,
  palceholder,
  errorText,
  warningText,
  type,
  meta: { touched, error, warning, valid },
}) => (
  <div className="col-md-6">
    <div className={`form-group ${touched && error && "has-danger"}`}>
      <label className="control-label">{label}</label>
      <input
        type={type}
        {...input}
        className="form-control"
        placeholder={palceholder}
      />
      {touched && error && (
        <span className="form-control-feedback">{errorText ?? error}</span>
      )}
    </div>
  </div>
);

const renderSelect = ({
  multi,
  input,
  placeholder,
  options,
  label,
  palceholder,
  errorText,
  warningText,
  type,
  meta: { touched, error, warning, valid },
}) => {
  return (
    <div className="col-md-6">
      <div className={`form-group ${touched && error && "has-danger"}`}>
        <label className="control-label">{label}</label>
        <Select
          placeholder={placeholder}
          value={input.value}
          {...input}
          onBlur={() => {
            input.onBlur(input.value);
          }}
          options={options}
          isMulti={multi}
        />
        {touched && error && (
          <span className="form-control-feedback">{errorText}</span>
        )}
      </div>
    </div>
  );
};

const validate = (values) => {
  values = values.toJS();

  let errors = {};

  if (!values.username) {
    errors.username = true;
  }

  const passwordErrors = validatePassword(
    values.password,
    values.confirm_password
  );
  errors = { ...errors, ...passwordErrors };

  if (!values.userGroupId) {
    errors.userGroupId = true;
  }

  if (values.userGroupId && values.userGroupId.label === "Insurance") {
    if (!values.insuranceId) {
      errors.insuranceId = true;
    }
  } else {
    if (!values.companyId) {
      errors.companyId = true;
    }
  }

  return errors;
};

export default reduxForm({
  validate,
  form: "NewUserForm",
})(injectIntl(NewUserForm));
