import axios from "axios";
import { store } from "react-notifications-component";
import messages from "./messages";
import {
  ROOT_URL,
  notification,
  GET_USERS,
  GET_USER_GROUPS,
  LOADING,
  UPDATE_USER_IMAGE,
} from "../constants";

export const getUsers = (data = "limit=10&offset=0", intl) => {
  return (dispatch) => {
    dispatch({ type: LOADING, payload: true });
    axios
      .get(`${ROOT_URL}/users?${data}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: GET_USERS, payload: response.data });
          dispatch({ type: LOADING, payload: false });
        }
      })
      .catch(({ response }) => {
        store.addNotification({
          title: intl.formatMessage(messages.errorTitle),
          message: intl.formatMessage(messages.commonError),
          type: "warning",
          ...notification,
        });
        dispatch({ type: LOADING, payload: false });
      });
  };
};

export const updatePassword = ({ password }, intl) => {
  return (dispatch) => {
    dispatch({ type: LOADING, payload: true });
    axios
      .post(`${ROOT_URL}/users/update-password`, { password })
      .then((response) => {
        if (response.status === 200) {
          store.addNotification({
            title: "Success",
            message: "Successfully updated password",
            type: "success",
            ...notification,
          });
          dispatch({ type: LOADING, payload: false });
        }
      })
      .catch(({ response }) => {
        dispatch({ type: LOADING, payload: false });
        store.addNotification({
          title: intl.formatMessage(messages.errorTitle),
          message: intl.formatMessage(messages.commonError),
          type: "warning",
          ...notification,
        });
      });
  };
};

export const getUserGroups = (data, intl) => {
  return (dispatch) => {
    dispatch({ type: LOADING, payload: true });
    axios
      .get(`${ROOT_URL}/userGroups?${data}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: GET_USER_GROUPS, payload: response.data });
          dispatch({ type: LOADING, payload: false });
        }
      })
      .catch(({ response }) => {
        store.addNotification({
          title: intl.formatMessage(messages.errorTitle),
          message: intl.formatMessage(messages.commonError),
          type: "warning",
          ...notification,
        });
        dispatch({ type: LOADING, payload: false });
      });
  };
};

export const addUser = (data, intl) => {
  return (dispatch) => {
    axios
      .post(`${ROOT_URL}/users`, data)
      .then((response) => {
        if (response.status === 200) {
          let rqst = "group=" + data.originPage.group + "&limit=10&offset=0";
          dispatch(getUsers(rqst, intl));
          dispatch({ type: LOADING, payload: false });
        }
      })
      .catch(({ response }) => {
        store.addNotification({
          title: intl.formatMessage(messages.errorTitle),
          message: intl.formatMessage(messages.commonError),
          type: "warning",
          ...notification,
        });
        dispatch({ type: LOADING, payload: false });
      });
  };
};

export const editUser = (data, id, intl, flag) => {
  return (dispatch) => {
    axios
      .put(`${ROOT_URL}/users/${id}`, data)
      .then((response) => {
        if (response.status === 200) {
          if (flag === 1) {
            dispatch({ type: UPDATE_USER_IMAGE, payload: data.profileImage });
          }
          let rqst = "group=" + data.originPage.group + "&limit=10&offset=0";
          dispatch(getUsers(rqst, intl));
          store.addNotification({
            title: intl.formatMessage(messages.successTitle),
            message: intl.formatMessage(messages.commonEditSuccess),
            type: "success",
            ...notification,
          });
          dispatch({ type: LOADING, payload: false });
        }
      })
      .catch(({ response }) => {
        store.addNotification({
          title: intl.formatMessage(messages.errorTitle),
          message: intl.formatMessage(messages.commonError),
          type: "warning",
          ...notification,
        });
        dispatch({ type: LOADING, payload: false });
      });
  };
};

export const deleteUser = (data, intl) => {
  return (dispatch) => {
    dispatch({ type: LOADING, payload: true });
    axios
      .delete(`${ROOT_URL}/users/${data.id}`)
      .then((response) => {
        if (response.status === 200) {
          let rqst = "group=" + data.originPage.group + "&limit=10&offset=0";
          dispatch(getUsers(rqst, intl));
          store.addNotification({
            title: intl.formatMessage(messages.successTitle),
            message: intl.formatMessage(messages.commonDeleteSuccess),
            type: "success",
            ...notification,
          });
          dispatch({ type: LOADING, payload: false });
        }
      })
      .catch(({ response }) => {
        store.addNotification({
          title: intl.formatMessage(messages.errorTitle),
          message: intl.formatMessage(messages.commonError),
          type: "warning",
          ...notification,
        });
        dispatch({ type: LOADING, payload: false });
      });
  };
};
