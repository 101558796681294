import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { Field, reduxForm } from "redux-form/immutable";
// import Immutable from 'immutable';

import { FormattedMessage, injectIntl } from "react-intl";

import { otherModalStyles } from "../../constants";

const AddFollowersForm = ({
  initialValues,
  openModal,
  closeModal,
  handleSubmit,
  messages,
  intl,
  addNewFollowers,
}) => {
  const [fields, setFields] = useState([]);

  const submitF = (data) => {
    data = data.toJS();
    addNewFollowers(data.followers);
    closeModal();
  };

  const addMore = () => {
    let f = fields;
    f.push("");
    setFields([...fields]);
  };

  const removeFollower = (key) => {
    let f = [...fields];
    f.splice(key, 1);
    setFields(f);
  };

  useEffect(() => {
    setFields(initialValues.toJS().followers);
  }, [initialValues]);

  // useEffect(() => {
  //     console.log(initialValues.toJS().followers)
  // }, [initialValues]);

  return (
    <Modal
      isOpen={openModal}
      contentLabel="Add Followers"
      onRequestClose={closeModal}
      ariaHideApp={false}
      style={otherModalStyles}
    >
      <div>
        <div className="card-header bg-info">
          <h4 className="m-b-0 text-white"> </h4>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit(submitF)}>
            <div className="form-body">
              <h3 className="card-title">
                {" "}
                <FormattedMessage {...messages.newFollowersString} />{" "}
              </h3>
              <hr></hr>
              <div className="row p-t-20">
                <div className="col-md-1 align-self-center">
                  <button
                    onClick={addMore}
                    type="button"
                    className="btn btn-circle btn-outline-success"
                  >
                    <i className="fa fa-plus"></i>
                  </button>
                </div>
              </div>
              {fields.map((f, i) => {
                return (
                  <div key={i} className="row p-t-20">
                    <div className="col-md-1 align-self-center">
                      {!f.email && (
                        <button
                          onClick={() => removeFollower(i)}
                          type="button"
                          className="btn btn-circle btn-success"
                        >
                          <i className="fa fa-minus"></i>
                        </button>
                      )}
                    </div>
                    <div className="col-md-5">
                      <Field
                        foll={f.email}
                        name={`followers[${i}].email`}
                        label={intl.formatMessage(messages.followerLabel)}
                        component={renderField}
                        errorText={intl.formatMessage(messages.errorText)}
                        palceholder={intl.formatMessage(
                          messages.followerPlaceholder
                        )}
                        type="email"
                      />
                    </div>
                    <div className="col-md-6"></div>
                  </div>
                );
              })}
              <div className="text-right p-t-20">
                <button
                  type="button"
                  onClick={() => closeModal()}
                  className="btn btn-outline-success m-r-10"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success">
                  <i className="fa fa-check"></i> Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
const renderField = ({
  foll,
  input,
  label,
  palceholder,
  errorText,
  warningText,
  type,
  meta: { touched, error, warning, valid },
}) => (
  <div className={`form-group ${touched && error && "has-danger"}`}>
    <label className="control-label">{label}</label>
    <input
      type={type}
      {...input}
      value={foll}
      className="form-control"
      placeholder={palceholder}
    />
    {touched && error && (
      <span className="form-control-feedback">{errorText}</span>
    )}
  </div>
);

const validate = (values) => {
  values = values.toJS();
  let errors = {};

  if (!values.name) {
    errors.name = true;
  }

  return errors;
};

export default reduxForm({
  validate,
  enableReinitialize: true,
  form: "AddFollowersForm",
})(injectIntl(AddFollowersForm));
