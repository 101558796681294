import axios from 'axios';
import { store } from 'react-notifications-component';
import messages from './messages';
import {
  ROOT_URL,
  notification,
  GET_SCANS,
  GET_SCAN,
  LOADING,
  INLINE_LOADING,
} from '../constants';
import { getCompanies } from './companyActions';

export const getScans = (data = 'limit=10&offset=0', intl) => {
  return (dispatch) => {
    dispatch({ type: LOADING, payload: true });
    axios
      .get(`${ROOT_URL}/scans?${data}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: GET_SCANS, payload: response.data });
          dispatch({ type: LOADING, payload: false });
        }
      })
      .catch(({ response }) => {
        console.log('error', response);
        store.addNotification({
          title: intl.formatMessage(messages.errorTitle),
          message: intl.formatMessage(messages.commonError),
          type: 'warning',
          ...notification,
        });
        dispatch({ type: LOADING, payload: false });
      });
  };
};

export const getScan = (id, intl) => {
  return (dispatch) => {
    dispatch({ type: LOADING, payload: true });
    axios
      .get(`${ROOT_URL}/scans/${id}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: GET_SCAN, payload: response.data });
          dispatch({ type: LOADING, payload: false });
        }
      })
      .catch(({ response }) => {
        console.log('error', response);
        store.addNotification({
          title: intl.formatMessage(messages.errorTitle),
          message: intl.formatMessage(messages.commonError),
          type: 'warning',
          ...notification,
        });
        dispatch({ type: LOADING, payload: false });
      });
  };
};

export const saveSnapShot = (image, id, intl) => {
  return (dispatch) => {
    dispatch({ type: INLINE_LOADING, payload: true });
    axios
      .post(`${ROOT_URL}/scans/snapshot/${id}`, image)
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: INLINE_LOADING, payload: false });
        }
      })
      .catch(({ response }) => {
        console.log('error', response);
        store.addNotification({
          title: intl.formatMessage(messages.errorTitle),
          message: intl.formatMessage(messages.commonError),
          type: 'warning',
          ...notification,
        });
        dispatch({ type: INLINE_LOADING, payload: false });
      });
  };
};

export const addFollowers = (followers, id, intl) => {
  return (dispatch) => {
    dispatch({ type: LOADING, payload: true });
    axios
      .put(`${ROOT_URL}/scans/${id}`, { followers })
      .then((response) => {
        if (response.status === 200) {
          store.addNotification({
            title: intl.formatMessage(messages.successTitle),
            message: intl.formatMessage(messages.commonSuccess),
            container: 'top-right',
            type: 'success',
            ...notification,
          });
          dispatch(getScan(id, intl));
          dispatch({ type: LOADING, payload: false });
        } else {
          store.addNotification({
            title: intl.formatMessage(messages.errorTitle),
            message: intl.formatMessage(messages.commonError),
            type: 'warning',
            ...notification,
          });
          dispatch({ type: LOADING, payload: false });
        }
      })
      .catch(({ response }) => {
        store.addNotification({
          title: intl.formatMessage(messages.errorTitle),
          message: intl.formatMessage(messages.commonError),
          type: 'warning',
          ...notification,
        });
        // console.log("error", response)
        dispatch({ type: LOADING, payload: false });
      });
  };
};

export const sendEmail = (id, intl) => {
  return (dispatch) => {
    dispatch({ type: LOADING, payload: true });
    axios
      .get(`${ROOT_URL}/scans/${id}/mails`)
      .then((response) => {
        if (response.status === 200) {
          store.addNotification({
            title: intl.formatMessage(messages.successTitle),
            message: intl.formatMessage(messages.successSentMail),
            type: 'success',
            ...notification,
          });
          dispatch({ type: LOADING, payload: false });
        }
      })
      .catch(({ response }) => {
        console.log('error', response);
        store.addNotification({
          title: intl.formatMessage(messages.errorTitle),
          message: intl.formatMessage(messages.commonError),
          type: 'warning',
          ...notification,
        });
        dispatch({ type: LOADING, payload: false });
      });
  };
};

export const deleteScan = (data, intl) => {
  return (dispatch) => {
    dispatch({ type: LOADING, payload: true });
    axios
      .delete(`${ROOT_URL}/scans/${data.id}`)
      .then((response) => {
        if (response.status === 200) {
          let rqst = '&limit=10&offset=0';
          dispatch(getScans(rqst, intl));
          store.addNotification({
            title: intl.formatMessage(messages.successTitle),
            message: intl.formatMessage(messages.commonDeleteSuccess),
            type: 'success',
            ...notification,
          });
          dispatch({ type: LOADING, payload: false });
        }
      })
      .catch(({ response }) => {
        store.addNotification({
          title: intl.formatMessage(messages.errorTitle),
          message: intl.formatMessage(messages.commonError),
          type: 'warning',
          ...notification,
        });
        dispatch({ type: LOADING, payload: false });
      });
  };
};

export const editScan = (data, id, intl) => {
  return (dispatch) => {
    dispatch({ type: LOADING, payload: true });
    axios
      .put(`${ROOT_URL}/scans/${id}`, data)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getCompanies('', intl));
          store.addNotification({
            title: intl.formatMessage(messages.successTitle),
            message: intl.formatMessage(messages.commonEditSuccess),
            type: 'success',
            ...notification,
          });
          dispatch({ type: LOADING, payload: false });
        }
      })
      .catch(({ response }) => {
        console.log('error', response);
        store.addNotification({
          title: intl.formatMessage(messages.errorTitle),
          message: intl.formatMessage(messages.commonError),
          type: 'warning',
          ...notification,
        });
        dispatch({ type: LOADING, payload: false });
      });
  };
};

export const getScanFileData = (id) => {
    return axios
      .get(`${ROOT_URL}/files/${id}/link`)
      .catch(({ response }) => {
        console.log('error', response);
      });
};

export const getScanSnapshotData = (scanId, snapshotId) => {
  return axios
    .get(`${ROOT_URL}/scans/${scanId}/snapshots/${snapshotId}/link`)
    .catch(({ response }) => {
      console.log('error', response);
    });
};